#modal-forward .picture {
    width: 25px;
    height: 25px;
    left: 13px;
    border-radius: 50%
}
#modal-forward .me-auto {
    display: inline-block;
    width: fit-content !important;
    margin-left: 5px !important;
}
