$nav-background: #303841
$panel-background: #363e47

.info
  width: 100%
  height: 100%
  background: white
  display: flex
  flex-direction: column
  justify-content: space-between
  overflow-y: hidden

  .top
    display: flex
    flex-direction: column
    align-items: center

  .logo
    padding: 60px 100px 20px 100px
    margin-bottom: -40px

    img
      width: 80px

  .text
    text-align: center
    padding: 40px
    font-size: 14px
    color: #666
