.session-detail
    .messages-wrapper
        max-height: 80vh
        overflow-y: auto
        background: #f5f5f5
        padding-top: 20px
    .session-summary
        &--action
            text-align: center
        &--content
            margin-top: 15px
            p
                margin-bottom: 7px
            li
                margin-bottom: 7px
            h1
                font-size: 17px
                color: #0067ac
                font-weight: 600
                text-align: center
            h2
                font-size: 15px
                color: #0067ac
                font-weight: 600
            h3
                font-size: 14px
                color: #0067ac
                font-weight: 600
            .ant-card
                margin-bottom: 15px
            .ant-tag
                max-height: 60vh
                overflow-y: auto
                width: 100%
                white-space: normal
                font-size: 14px
                padding: 10px

.ant-spin
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center