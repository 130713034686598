.room
  width: 100%
  height: 54px
  border-bottom: 1px solid rgba(218, 220, 224, 0.3)
  cursor: pointer
  &.highlight
    .text
      .title
        color: #000
        font-weight: 800
      .message
        font-weight: 700
    .room_date
      font-weight: 700
  .room_date
    line-height: 22px
  .icon-block
    font-size: 18px
    color: #dd2c00
    padding: 9px
  .room_noti
    font-size: 10px
    border-radius: 10px
    border: solid 1px #00c620
    background-color: #00c620
    width: 7px
    height: 7px
    display: inline-block
    color: #fff
    margin-left: 10px
  .dot
    margin-left: -26px
    margin-right: 11px
    margin-top: 28px
    width: 11px
    height: 11px
    border-radius: 8px
    background: gray
    border: 2px solid #fff
  .online
    background: #55d48b
  .away
    background: orange
  .busy
    background: #d61314
  .offline
    background: gray
  .profile
    overflow: hidden
    width: 40px
    height: 40px
    border-radius: 20px
    margin: 7px 12px
    .img
      display: flex
      width: 40px
      height: 40px
      border-radius: 20px
      justify-content: center
      align-items: center
      background: #666
      color: #f7f7f8
      font-size: 18px
  .text
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: center
    .title
      font-weight: bold
      font-size: 13px
    .message
      font-size: 11px
  .controls
    .date
      font-size: 10px
      padding-right: 7px
      text-align: right
      padding-top: 2px
    .button
      color: #666
      font-size: 14px
      padding: 5px
      cursor: pointer
      display: flex
      justify-content: center
      align-items: center
      height: 100%
    .button:hover
      color: #000

.room:hover
  background: #def1ff
.room-active
  background: #def1ff !important
  border-right: 4px solid #0067ac

.message-ads-room
  margin-right: 5px
  margin-top: 5px
  padding: 0 4px
  border: 1px solid green
  border-radius: 5px
  color: green
  background: #a0eea0
  font-size: 10px
  font-weight: 600