.note-lists
  .item
    font-size: 14px
    margin-top: 10px
    padding-top: 10px
    border-top: 1px dashed #e0e0e0
    &:first-child
      margin-top: 0
      padding-top: 0
      border-top: none
    textarea
      font-size: 13px
    .time-action
      display: flex
      justify-content: space-between
      margin-bottom: 5px
      .time
        font-size: 11px
      span
        cursor: pointer
      .btn-delete
        color: #dd2c00
    .note-desc
      font-size: 13px
      padding: 4px 15px
      background: #f0f3fb
      border-radius: 10px
      width: fit-content
.note-content
  textarea
    font-size: 13px !important
    border: none