.img_action_message {
    width: 20px;
    height: 15px;
    opacity: 0.8;
}

.action_message {
    display: inline-block;
    border-radius: 10px;
    position: absolute;
    bottom: 0px;
    background: #fff;
    display: none;
    width: 140px;
}
.content-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.action_message span {
    margin-right: 10px;
}

.action_message span svg {
    color:#9fadb8;
}


.action_message_left {
    right: -150px;
}

.action_message_right {
    left: -150px;
}

.action_message span {
    cursor: pointer;
}

.action_message .action_quick {
    padding: 0 10px;
    text-align: center;
    margin: 0 auto;
}

.action_message_show{
    display: block;
    text-align: center;
    margin: 0 auto;
}

element.style {
}
.hover_emoji_right {
    left: 20px;
}
.hover_emoji {
    border: 1px solid #0000002b;
    border-radius: 50%;
    padding: 2px 4px;
    text-align: center;
    position: absolute;
    bottom: -14px;
    z-index: 1;
    font-size: 13px;
    background: #fff;
}

.hover_emoji_left {
right:10px;
}

.hover_emoji_right {
left: 10px;
}

.hover_emoji:hover{
cursor: pointer;
}

.hover_edit {
    text-align: center;
    position: absolute;
    opacity: 0.3;
}

.hover_edit_right {
    left: -25px;
    bottom: 10px;

}
.hover_edit_left {
    right: -25px;
    bottom: 10px;
}
.popup_emoji_left {
    position: absolute;
    right: -95px;
    bottom: -5px;
    padding: 0px 10px;
    background: #fff;
    border-radius: 20px;
    z-index: 2;
    border: 1px solid #0000002b;
    width: 255px;
}

.popup_emoji_right {
    position: absolute;
    left: -95px;
    bottom: -5px;
    padding: 0px 10px;
    background: #fff;
    border-radius: 20px;
    z-index: 2;
    border: 1px solid #0000002b;
    width: 255px;
}

.popup_emoji_left span {
font-size: 20px;
margin-left: 6px;
padding: 5px;
}

.popup_emoji_right span{
font-size: 22px;
margin-left: 6px;
padding: 5px;
}

.popup_emoji_left span:hover , .popup_emoji_right span:hover{
cursor: pointer;
}
.reply_message {
background: rgb(250, 250, 250);
padding: 12px;
border-radius: 10px;
margin-bottom: 10px;
}

.block_reply_message {
    border-left: 3px solid rgb(199, 199, 199);
    padding-left: 10px;
    overflow: hidden;
}
.block_reply_message .content-image {
    width: 200px!important;
    height: 200px!important;
    position: relative;
}
.content-image {
    width: 100%;
    height: 100%;
    position: relative;
}
.btn-download-img{
    position: absolute;
    top:0;
    left: 0;
    z-index: 1;
    color: #252423;
}
.action_three_dot {
    position: absolute;
    display: block;
    background: #fff;
    padding: 17px 25px;
    border-radius: 15px;
    width: 140px;
    bottom: 110%;
    color: #9fadb8 !important;
    z-index: 1;
    right: 0px;
    text-align: left;
}

.action_three_dot > span{
    display: block;
}

.action_three_dot hr{
    margin-top: 5px;
    margin-bottom: 5px;
}
.list-emoji-check  .emoji-message {
    border: 1px solid #0000002b;
    border-radius: 20px;
    background: #fff;
    padding: 2px 8px;
    margin-bottom: 5px;
}

.number_emoji {
    color: #00000052;
    margin-right: 5px;
}

.list-emoji-check .emoji-message:hover .number_emoji {
    color: #000;
}

.list-emoji-check {
    margin: 10px 0px;
    position: absolute;
    z-index: 10;
    left: 40px;
    top: 20px
}
.list-emoji-left{
    left: 65px;
}
.number_emoji_left{
    margin-left: 5px;
}
.extraClass {
    font-size: 20px !important;
    pointer-events: auto !important;
}
.extraClass:hover {
     visibility: visible !important;
     opacity: 1 !important;
 }
.emoji_detail {padding: 3px}
.notification-not-read {font-weight: bold}
.__react_component_tooltip {
    position: initial!important;
}
.avatar_view {
    display: block!important;
    border-radius: 50%;
    margin-left: 20px;
    float: right;
    width: 100%;
    text-align: right;
    padding: 0px 30px 30px 0px!important;
}
.avatar_view .picture .img {
    width: 20px!important;
    height: 20px!important;
    border-radius: 50%!important;
    font-size: 10px!important;
    display: inline-block!important;
    text-align: center;
    align-items: center!important;
    line-height: 20px;
}
.avatar_view .text_seen_by {
    width: 50px!important;
    height: 20px!important;
    border-radius: 50%!important;
    font-size: 10px!important;
    display: inline-block!important;
    text-align: center;
    align-items: center!important;
    line-height: 20px;
}

.avatar_view .number_view {
    height: 20px!important;
    border-radius: 50%!important;
    font-size: 10px!important;
    display: inline-block!important;
    text-align: center;
    align-items: center!important;
    line-height: 20px;
    padding: 0 5px;
}

/* .__react_component_tooltip {
    display: inherit!important;
    padding: 0px!important;
} */
