.limit-text
  display: -webkit-box
  -webkit-line-clamp: 3
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis
  
.slider-wrapper
  display: flex
  width: 660px
  overflow-x: scroll
  cursor: grab
  user-select: none
  scrollbar-width: none 
  // white-space: nowrap
  .slick-slide
    > div 
      margin-right: 10px
  .slide-item
    width: 100%
    background: #e8ebfa
    max-width: 254px
    min-width: 254px
    padding: 10px
    margin-left: 10px
    border-radius: 10px
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.03)
    .product-image
      .ant-image
        width: 100%
      img
        width: 100%
        overflow: hidden
        object-fit: cover
        background-size: cover
        background-position: center
        border-radius: 10px
        aspect-ratio: 1.75/1
    .product-info
      .product-name
        font-weight: 600
        font-size: 14px
        height: 42px
        margin-bottom: 5px
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        overflow: hidden
        text-overflow: ellipsis
      .product-description
        font-size: 14px
        height: 63px
      .product-price
        color: #ab2a2a
        font-weight: 600
        text-align: center
        text-transform: capitalize
        font-size: 14px
      .product-cta
        > a
          color: #fff !important
          &:hover
            text-decoration: none

.slick-prev
  font-size: 30px
  color: #000
  &:hover
    color: #1890ff

.slick-next
  font-size: 30px
  color: #000
  &:hover
    color: #1890ff

.slider-wrapper::-webkit-scrollbar
  display: none

.message-slider
  padding: 0 !important
  background: unset !important
  box-shadow: unset !important

.chat-client 
  .slider-wrapper 
    .slide-item
      margin-left: 0
      margin-right: 10px
    .slide-spacer
      min-width: 100px

@media screen and (max-width: 1440px)
  .slider-wrapper
    width: 500px
    .slide-item
      max-width: 200px
      min-width: 200px

@media screen and (max-width: 767px)
  .chat-client 
    .slider-wrapper
      .slide-item
        max-width: 230px
        min-width: 230px

@media screen and (max-width: 500px)
  .chat-client 
    .slider-wrapper
      width: 375px

@media screen and (max-width: 400px)
  .chat-client 
    .slider-wrapper
      width: 326px

