.top-bar
  width: 100%
  min-height: 54px
  max-height: 54px
  border-bottom: 1px solid rgba(218, 220, 224, 0.5)
  background: #fff
  position: relative
  .dot
    margin-left: -26px
    margin-right: 11px
    margin-top: 28px
    width: 11px
    height: 11px
    border-radius: 8px
    background: gray
    border: 2px solid #fff
  .online
    background: #55d48b
  .away
    background: orange
  .busy
    background: #d61314
  .offline
    color: gray
  .text
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: center
    .title
      font-weight: bold
      font-size: 13px
      text-transform: capitalize
    .message
      font-size: 11px
  .profile.conversation
    position: relative
    overflow: unset
    width: 40px
    height: 40px
    border-radius: 20px
    margin: 7px 12px 7px 5px
    .social-icon
      position: absolute
      z-index: 1
      bottom: -2px
      right: -3px
    .picture
      border-radius: 50%
      width: 100%
    .img
      display: flex
      width: 40px
      height: 40px
      border-radius: 20px
      justify-content: center
      align-items: center
      background: #0167ac
      color: #f7f7f8
      font-size: 18px
  .nav
    display: flex
    padding: 0 9px
    .icon-slash-chat
      cursor: pointer
      padding: 9px
      font-size: 18px
    .icon-remove-chat
      color: #dd2c00
      cursor: pointer
      padding: 9px
      font-size: 18px
    .icon-slash-chat
      color: #666666
    .button
      color: #666
      font-size: 18px
      padding: 9px
      cursor: pointer
    .button:hover
      color: #000
    .button.active
      color: #da7d02 !important

.chat-client
  .top-bar
    height: 47px
    min-height: 47px
    max-height: 47px
    background: #1677ff
    color: #fff
    .profile.conversation 
      width: 35px
      height: 35px
      margin: 5px 10px 5px 5px
      .img
        width: 35px
        height: 35px
        border: 1px solid #12a0ff
  .room-title
    font-size: 14px !important


@media screen and (min-width: 700px)
  .mobile
    display: none
