.top-bar
  width: 100%
  height: 54px
  border-bottom: 1px solid rgba(218, 220, 224, 0.5)
  background: #fff
  &.detail-customer
    position: relative
    .button
      position: absolute
      left: 10px
      color: #666
      font-size: 19px
      padding: 9px
      cursor: pointer
    .nav
      display: flex
      padding: 0 9px
      align-items: center
      justify-content: center
      width: 100%
    .button:hover
      color: #000
  .panel-title
    font-weight: 600
    margin-top: 0 !important
  .profile
    overflow: hidden
    width: 40px
    height: 40px
    border-radius: 20px
    margin: 7px 12px

@media screen and (min-width: 700px)
  .mobile
    display: none
