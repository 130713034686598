$nav-width: 96px
$main-color: #ff0000
$nav-background: #0167ac
$panel-background: #363e47

.sweet-alert
  z-index: 99999
.btn
  font-size: 13px !important
.btn-primary
  background-color: #0067ac !important
  border-color: #0067ac !important
  padding: 5px 10px !important
.prevent-events
  pointer-events: none
  opacity: 0.3
.details-room
  position: relative
  max-height: calc(100% - 70px)
  overflow: hidden overlay
  background: #fcffff
  display: flex
  flex-direction: column
  align-items: center
  > div
    width: 100%
  .loading-more
    height: 200px
    max-height: 200px
    margin-top: 0
  .search-header
    display: flex
    justify-content: space-evenly
    width: 100%
    padding: 7px 0
    border-bottom: 1px solid #f1f1f1
    .btn-search
      font-size: 13px
      border: 1px solid #0067ac
      color: #0067ac
      font-weight: 600

  .customer-points
    font-size: 14px

  .title
    font-size: 14px
    margin-bottom: 5px

  .notice-text
    text-align: center
    font-size: 14px
    padding-top: 12px
    color: $panel-background

  .images
    display: flex
    flex-direction: column
    flex-shrink: 1
    margin: 2px 0 2px 0
    overflow-y: scroll
    overflow-x: hidden
    scrollbar-width: none

    .row
      display: flex
      flex-direction: row
      min-height: 149px

      img:first-child
        margin-left: 2px
        margin-right: 1px

      img
        width: 147px
        min-height: 147px
        max-height: 147px
        height: 147px
        object-fit: cover
        margin-left: 1px
        margin-right: 2px
        margin-bottom: 2px
        cursor: pointer

  .images::-webkit-scrollbar
    display: none

  .notice-text
    padding: 20px 20px

.members
  display: flex
  flex-direction: column
  background: white
  color: #666
  width: 100%
  height: 100%
  padding-right: 20px
  overflow-x: hidden
  overflow-y: scroll

  .member
    display: flex
    align-items: center
    height: 54px
    border-bottom: 1px solid rgba(218, 220, 224, 0.5)
    font-size: 14px

    img
      width: 40px
      height: 40px
      border-radius: 20px
      margin: 7px 14px 7px 16px

    .img
      width: 40px
      height: 40px
      border-radius: 20px
      display: flex
      justify-content: center
      align-items: center
      background: $nav-background
      color: #f7f7f8
      margin: 7px 14px 7px 16px

    .text
      flex-grow: 1

  .online
    color: #55d48b
    svg
      background-color: #55d48b
      border-radius: 50%
  .away
    color: orange
    svg
      background-color: orange
      border-radius: 50%
  .busy
    color: #d61314
    svg
      background-color: #d61314
      border-radius: 50%
  .offline
    color: gray
    svg
      background-color: gray
      border-radius: 50%
  .status
    margin: 7px 0 7px 16px
  .remove-member
    cursor: pointer
    padding: 5px

.social-linked
  display: flex
  justify-content: start
  align-items: center
  flex-wrap: wrap
  .item
    position: relative
    width: 35px
    height: 35px
    margin-left: 10px
    margin-bottom: 5px
    cursor: pointer
    .avatar
      border-radius: 50%
    .social-icon
      position: absolute
      right: -15px
      bottom: -3px
    .img
      width: 35px
      height: 35px
      border-radius: 50%
      display: flex
      justify-content: center
      align-items: center
      background: $nav-background
      color: #f7f7f8
      font-size: 17px


::-webkit-scrollbar
  width: 4px
  background-color: #F5F5F5

::-webkit-scrollbar-track
  background-color: #F5F5F5
  border-radius: 10px

::-webkit-scrollbar-thumb
  border-radius: 10px
  background-color: #d0d0d0

@media screen and (max-width: 1200px)
  .details-room .search-header .btn-search
    font-size: 11px !important

  .search-bar button
    font-size: 10px !important
    padding: 5px !important