.pin_message p {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 13px;
}

.img_pin_message {
  display: inline-block;
  width: 4%;
  vertical-align: top;
}

.img_pin_message svg {
  width: 100px;
}

.block_pin_message {
  max-height: 90px;
  width: 90%;
  display: inline-block;
  overflow: hidden;
}
.pin_message {
  background: #fff;
  padding: 10px 10px;
  width: 100%;
  position: absolute;
  top: 102%;
  z-index: 999;
}

.action_pin_message {
  height: 100%;
  padding-left: 30px;
}

.tooltip {
  position: relative;
}

.tooltip-content {
  position: absolute;
  background-color: #000;
  text-align: center;
  color: #fff;
  transform: translateX(-50%);
  white-space: nowrap;
  border-radius: 4px;
  padding: 6px 10px;
}

.tooltip-content:before {
  content: "";
  position: absolute;
}

/* POSITION TOP */
.tooltip-content.top {
  bottom: 100%;
  left: 50%;
  margin-bottom: 10px;
}
.tooltip-content.top:before {
  top: 100%;
  left: 50%;
  margin-left: -4px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000;
}