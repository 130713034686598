.customer-info-wrap
    background: #f0f3fb
    .customer-info-header
        display: flex
        align-items: center
        .customer-info-head-right
            max-width: 70%
            .name
                margin: 0
.customer-info-btn
    position: absolute
    right: 10px
    top: 60px
    .action-update-title
        cursor: pointer
        background: #d9d9d9
        padding: 3px 7px
        margin-left: 5px
    .customer-delink
        font-size: 11px
        padding: 3px 7px
        background: #dd2c00
        border-radius: 5px
        margin-left: 5px
        color: #fff
        text-decoration: none
        white-space: nowrap
        img
            filter: brightness(0) invert(1)
        &:hover
            color: #fff
            opacity: .7

.customer-info
    padding: 5px 20px
    width: 100%
    .btn-detail-customer
        color: #0067ac
        text-decoration: none
        font-size: 14px
        cursor: pointer
    .title
        img
            margin-right: 10px
    .display_none
        display: none
    
    .details-button
        min-height: 40px
        width: 100%
    &--phone
        color: #2962ff
        cursor: pointer
        font-size: 13px
        font-weight: 500

.profile
    margin: 15px
    width: 50px
    height: 50px
    border-radius: 50%
    overflow: hidden
    flex-shrink: 0
    text-transform: capitalize

.profile .img
    width: 50px
    height: 50px
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    background: #0167ac
    color: #f7f7f8
    font-size: 30px

.userchat-name-block
    font-size: 16px
    font-weight: 600
    margin-top: -14px
    text-transform: capitalize
    .userchat-name
        margin-right: 10px
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-line-clamp: 1
        -webkit-box-orient: vertical