.streams
  width: 100%
  height: 100%
  background: black
  flex-grow: 1

  .waiting
    color: #f7f7f7
    font-size: 28px
    font-weight: bold

  .video-container
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: stretch
    align-items: stretch
    .video-row
      display: flex
      flex-direction: row
      justify-content: stretch
      align-items: stretch
      flex: 1 1 0
    .video-wrapper
      flex: 1 1 0
      position: relative
    .remote-video
      position: absolute
      left: 0
      top: 0
      height: 100%
      width: 100%
      object-fit: cover
      background: black
      z-index: 0
    .rotated
      transform: rotateY(180deg)
      -webkit-transform: rotateY(180deg)
      -moz-transform: rotateY(180deg)
