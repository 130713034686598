.top-bar
  width: 100%
  height: 54px
  border-bottom: 1px solid rgba(218, 220, 224, 0.5)
  background: #f0f3fb
  .profile
    overflow: hidden
    width: 40px
    height: 40px
    border-radius: 20px
    margin: 7px 12px
  .nav
    display: flex
    align-items: center
    padding: 0 9px
    .button
      color: #666
      font-size: 18px
      padding: 9px
      cursor: pointer
    .button:hover
      color: #000

@media screen and (min-width: 700px)
  .mobile
    display: none
