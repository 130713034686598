.action-tabs
  .ant-tabs
    .ant-tabs-nav
      margin-bottom: 0
    .ant-tabs-tab
      padding: 12px 20px
      font-weight: 600
      margin-left: 0
      width: 100%
    .ant-tabs-content
      padding: 12px 10px
      background: #f0f3fb
      
.ant-card
  .ant-card-head
    background: #0067ac
    color: #fff


@media screen and (min-width: 1600px)
  .action-tabs
    .ant-tabs
      .ant-tabs-nav-list
        .ant-tabs-tab
          margin-left: 30px
          &:first-child
            margin-left: 0