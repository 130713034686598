.search-result-wrapper
  padding-right: 10px !important
  padding-bottom: 30px
  .order-item
    position: relative
    margin-bottom: 5px
    padding: 5px 0
    border-bottom: 1px solid #f1f1f1
    .btn-detail-order
      color: #0028FA
      text-decoration: none
      font-size: 14px
      cursor: pointer
      a
        color: #0028fa !important
    p
      font-size: 14px
      margin-bottom: 7px
      margin-top: 0 !important
      &:last-child
        justify-content: space-between
    .order-code
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 10px
      font-size: 14px
      .code
        font-weight: 600
      .status
        padding: 5px 10px
        font-size: 13px
        background: #0255c7
        border-radius: 5px
        color: #fff