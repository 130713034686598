.session-chat-block
  max-height: 400px
  overflow-y: auto
  padding-bottom: 5px
  &--item
    display: flex
    margin-bottom: 10px
    align-items: start
    user-select: none
    cursor: pointer

    &--label
      position: relative
      font-size: 12px
      font-weight: 600
      margin-right: 10px
      text-align: center
      background: #f0f3fb
      padding: 5px
      border-radius: 10px
      width: 59px
      min-width: 59px
      &::after
        content: ""
        display: block
        width: 1px
        height: 26px
        background: #cacaca
        position: absolute
        bottom: -26px
        left: 50%
        transform: translateX(-50%)
        z-index: 0
    &--value
      padding-bottom: 10px
      border-bottom: 2px solid #f0f3fb
      width: 100%
      &--title
        margin-bottom: 5px
        overflow: hidden
        text-overflow: ellipsis
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical
        &--text
          margin-left: 5px
      &--time
        font-size: 11px

.ant-tag
  margin-right: 3px