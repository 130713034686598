.room
  width: 100%
  min-height: 80px
  height: auto
  padding: 8px 0
  border-bottom: 1px solid rgba(218, 220, 224, 0.3)
  cursor: pointer
  overflow: hidden
  .profile
    position: relative
    width: 45px
    min-width: 45px
    height: 45px
    margin: 7px 12px
    border-radius: 0
    .picture
      border-radius: 50%
    .social-icon
      position: absolute
      right: -2px
      bottom: -1px
      z-index: 0
    .img
      display: flex
      width: 40px
      height: 40px
      border-radius: 20px
      justify-content: center
      align-items: center
      background: #0167ac
      color: #f7f7f8
      font-size: 18px
  .text
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: center
    .channel-name
      padding: 1px 7px
      background: #c5e8ff
      border-radius: 3px
      color: #004e82
      font-size: 11px
      width: -moz-fit-content
      width: fit-content
      margin-top: 5px
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 1
      -webkit-box-orient: vertical
    .title
      font-weight: normal
      font-size: 13px
      margin-bottom: 5px
      font-weight: 500
      text-transform: capitalize
    .message
      font-size: 12px
      margin-bottom: 3px
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 1
      -webkit-box-orient: vertical
  .controls
    text-align: right
    min-width: 50px
    .date
      font-size: 11px
      color: #6c798f
      padding-right: 7px
      text-align: right
      padding-top: 2px
      white-space: pre-wrap
      word-wrap: break-word
      display: flex
      flex-direction: column
      align-items: end
    .date.selected
      color: #DA7D02
    .button
      min-width: 24px
      color: #666
      font-size: 14px
      padding: 5px
      cursor: pointer
      display: flex
      justify-content: center
      align-items: center
      height: 100%
    .button.selected
      color: #DA7D02
    .button:hover
      color: #000
    .button.selected:hover
      color: #DA7D02

.room:hover
  background: #f2f7f7
