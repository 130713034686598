.live-chat-form-started
  margin: 15px
  padding: 15px 20px
  background: #fff
  text-align: center
  border-radius: 10px
  box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.02)

.chat-client
  .ant-form-item-required
    font-weight: 600
  .ant-btn-primary
    font-weight: 600