.data-table
  width: 100%
  height: 100%
  overflow-y: scroll
  .data-create
    position: relative
    height: 0
    z-index: 1000
    top: 0
    right: 0
    width: 100%
    text-align: right
    button
      margin: 10px

.data-actions
  display: flex
  flex-direction: row
  .edit
    color: #da7d02
    padding: 10px 0
  .separator
    padding: 10px 0
  .delete
    color: #d61314
    padding: 10px 0

.admin
  .search-bar
    background: #f5f5f5
