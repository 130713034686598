.ml-15
  margin-left: 15px
.ml-10
  margin-left: 10px
.mr-10
  margin-right: 10px
.text-bold
  font-weight: 600
.text-right
  text-align: right
.search-result
  border-top: 1px solid #cacaca
  padding-top: 15px
  padding-left: 15px
  overflow-y: scroll
  overflow-x: hidden
  height: calc(100% - 60px)
  padding-bottom: 50px
  .search-result-wrapper
    .item
      display: flex
      justify-content: space-between
      font-size: 13px
      border-bottom: 1px solid #cacaca
      margin-top: 10px
      padding-right: 15px
      p
        margin-bottom: 10px
      .left
        width: 50%
        min-width: 50%
        margin-right: 5px
        .customer-name
          overflow: hidden
          text-overflow: ellipsis
          display: -webkit-box
          -webkit-line-clamp: 2
          -webkit-box-orient: vertical
          word-wrap: break-word
        .customer-code
          overflow: hidden
          text-overflow: ellipsis
          display: -webkit-box
          -webkit-line-clamp: 1
          -webkit-box-orient: vertical
          word-wrap: break-word
        .customer-label
          padding: 3px 7px
          background: #EF9F27
          width: fit-content
          border-radius: 5px
          color: #fff
      .right
        p
          overflow: hidden
          text-overflow: ellipsis
          display: -webkit-box
          -webkit-line-clamp: 1
          -webkit-box-orient: vertical
          word-wrap: break-word
        .customer-email
          overflow: hidden
          text-overflow: ellipsis
          display: -webkit-box
          -webkit-line-clamp: 1
          -webkit-box-orient: vertical
          word-wrap: break-word
        .customer-link
          font-size: 12px
          padding: 3px 7px
          background: #33CC00
          border-radius: 5px
          color: #000
          text-decoration: none
          white-space: nowrap
.action-group
  width: 100%
  .action-title
    display: flex
    justify-content: space-between
    font-weight: 600
    padding: 12px 15px
    border-bottom: 1px solid rgba(218, 220, 224, 0.5)
    cursor: pointer
    font-size: 14px
  .detail-action-group
    display: block
    .detail-action-item
      padding: 8px 7px
      display: flex
      align-items: center
      cursor: pointer
      border-radius: 5px
      margin-bottom: 7px
      font-size: 15px
      font-weight: 600
      background: #fff
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),0 1px 6px -1px rgba(0, 0, 0, 0.02),0 2px 4px 0 rgba(0, 0, 0, 0.02)
      .note-content
        width: 100%
      img
        margin-right: 15px
.details
  width: 300px
  height: 100%
  border-left: 1px solid rgba(218, 220, 224, 0.5)
  .notice
    font-size: 12px
    color: #666
    text-align: center
    padding: 18px
  .rooms
    height: calc(100% - 55px - 41px - 45px)
    overflow-y: scroll
    -webkit-overflow-scrolling: touch
.details.expand
  max-width: 100%
  min-width: 100%

.details.opens-info
  display: block
.details.close-info
  display: none

@media screen and (min-width: 1200px)
  .details
    max-width: 25vw
    min-width: 25vw

@media screen and (min-width: 1920px)
  .details
    max-width: 500px

.theme.light .details
  background: #f0f3fb

.theme.dark .details
  background: #303841

.active
  color: #0067ac !important

@media screen and (max-width: 768px)
  .customer-detail-visible
    display: none !important